<template>
  <div class="flex items-center justify-between mb-4">
    <div class="flex-shrink-0">
      <span class="text-2xl sm:text-3xl leading-none font-bold text-gray-900">{{ $filters.numf(total) }}</span>
      <h3 class="text-base font-normal text-gray-500">{{title}}</h3>
    </div>
    <div class="flex items-center justify-end flex-1 text-green-500 text-base font-bold"
     :class="{'text-red-500': Math.sign(compare) === -1}">
      {{compare}}%
      <span
      :class="{'transform-gpu rotate-180': Math.sign(compare) === -1}"
      >
        <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
        >
          <path fill-rule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
        </svg>
      </span>
    </div>
  </div>
  <div id="main-chart" style="min-height: 435px;">
    <div class="apexcharts-canvas apexcharts-theme-light">
      <LineChartWidgets :labels="labels" :datasets="datasets" :options="options"/>
      <table class="table-fixed mt-8 w-full border-1">
        <thead>
          <tr class=" bg-gray-100">
            <th>-</th>
            <th
             v-for="(d, di) in labels" :key="'di-'+di"
             class="text-sm font-bold p-3"
             >
               {{d}}
             </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, dti) in datasets" :key="'dti-'+dti">
            <td class="px-2 py-1 text-sm">{{data.label}}</td>
            <td class="px-2 py-1" v-for="(dd, ddi) in data.data" :key="'ddi-'+ddi">{{$filters.numf(dd)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import LineChartWidgets from '@/components/Widgets/LineChartWidgets';

export default{
  name: 'WeeklyLineComapre',
  data() {
    return {
    };
  },
  props: ['title', 'labels', 'datasets', 'total', 'compare', 'options'],
  setup() {
    return {
    };
  },
  components: {
    LineChartWidgets
  },
  methods:{
  }
};
</script>
