<template>
  <div class="flex py-48 justify-center items-center" v-if="loading">
    <Loading />
  </div>
  <div class="pt-6 px-4" v-else>
    <div class="flex justify-between items-center mb-4">
      <h1 class="">
        <span class="text-xl fot-bold text-gray-300 mr-4">Site Detail</span>
        <span class="text-xl fot-bold text-gray-800" v-if="site">{{site.name}}</span>
      </h1>
      <p>
        <router-link :to="$route.path + 'checker/'" class="text-sm font-semibold text-red-500">
          View Checker
        </router-link>
        <button class="sm:inline-flex ml-5 text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded text-sm px-2 py-1 text-center items-center" @click="toggleFlag()">Checker Add</button>
      </p>
    </div>
    <div class="" v-if="site">
      <div class="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  2xl:col-span-2">
          <h1>Weekly Clicks</h1>
          <WeeklyLineCompare
           v-if="weeklyClk.datasets"
           :title="weeklyClk.title" :labels="weeklyClk.labels" :datasets="weeklyClk.datasets" :total="weeklyClk.total" :compare="weeklyClk.comapre" />
        </div>
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  2xl:col-span-2">
          <h1>Weekly Impressions</h1>
          <WeeklyLineCompare
           v-if="weeklyImp.datasets"
           :title="weeklyImp.title" :labels="weeklyImp.labels" :datasets="weeklyImp.datasets" :total="weeklyImp.total" :compare="weeklyImp.comapre" />
        </div>
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  2xl:col-span-2">
          <h1>Weekly Positions</h1>
          <WeeklyLineCompare
           v-if="weeklyPos.datasets"
           :title="weeklyPos.title" :labels="weeklyPos.labels" :datasets="weeklyPos.datasets" :total="weeklyPos.total" :compare="weeklyPos.comapre" :options="weeklyPos.options" />
        </div>
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  2xl:col-span-2">
          <h1>Weekly CTR</h1>
          <WeeklyLineCompare
           v-if="weeklyCtr.datasets"
           :title="weeklyCtr.title" :labels="weeklyCtr.labels" :datasets="weeklyCtr.datasets" :total="weeklyCtr.total" :compare="weeklyCtr.comapre" />
        </div>
      </div>
      <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 my-8">
        <TopQuery :querylist="topQuery.querylist" :date="topQuery.date" />
      </div>
      <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 my-8">
        <TopUrl :list="topUrl.list" :date="topUrl.date"/>
      </div>
    </div>
    <CheckerAdd v-if="checkeradd" :selectedVal="site.id" />
  </div>
</template>

<script>
import Loading from '@/components/Atom/Loading'
import { db } from "../db";
import TopQuery from '@/components/Widgets/TopQuery'
import WeeklyLineCompare from '@/components/Widgets/WeeklyLineCompare'
import TopUrl from '@/components/Widgets/TopUrl'
import CheckerAdd from '@/components/Blocks/CheckerAdd'

export default {
  name: 'SiteDetail',
  data() {
    return {
      loading: true,
      topQuery: {},
      topUrl: {},
      date:'',
      site: '',
      weeklyClk: {},
      weeklyImp: {},
      weeklyPos: {},
      weeklyCtr: {},
      checkeradd: false
    }
  },
  setup() {
    return {
      db,
    }
  },
  components: {
    TopQuery,
    WeeklyLineCompare,
    Loading,
    TopUrl,
    CheckerAdd
  },
  mounted(){
    this.buildData();
  },
  provide() {
    return {
      toggleFlag: this.toggleFlag
    }
  },
  methods:{
    toggleFlag(){
      this.checkeradd = (this.checkeradd)? false:true;
    },
    async buildData(){
      // get site
      const site = await db.sites.where({"name": this.$route.params.id}).first();
      this.site = site;

      // get weekly clk
      this.weeklyClk = await this.getWeekyData(site, 'clk');

      // get weekly imp
      this.weeklyImp = await this.getWeekyData(site, 'imp');

      // get weekly ctr
      this.weeklyCtr = await this.getWeekyData(site, 'ctr', 'ave');

      // get weekly Pos
      this.weeklyPos = await this.getWeekyData(site, 'pos', 'ave');
      this.weeklyPos.options = {
        scales: {
          y: {
            reverse: true,
          }
        }
      }

      // get top query
      this.topQuery = await this.getQuerylist(site);

      // get top url
      this.topUrl = await this.getTopUrl(site);

      this.loading = false;
    },
    buildWeeks(startDate){
      const resuilt = [];
      const maxdays = 7;
      const weekdate = [
        {
          label: 'This Week',
          start: 0,
          thisweek: true
        },
        {
          label: 'Last Week',
          start: 7,
          thisweek: false
        },
      ];

      for(let w in weekdate){
        const tmp = {};
        tmp.label = weekdate[w].label;
        tmp.thisweek = weekdate[w].thisweek;
        tmp.dates = [];
        let cnt = 0;
        while (cnt < maxdays) {
          tmp.dates.push(this.$dayjs(startDate).subtract(weekdate[w].start, 'day').subtract(cnt, 'day').format('YYYYMMDD'))
          cnt++;
        }
        tmp.dates.reverse();
        resuilt.push(tmp);
      }
      return resuilt;
    },
    async getWeekyData(site, val, totalType){
      const final = {};
      final.labels = [];
      // build weeks
      const lastscs = await this.db.scs.where('sid').equals(site.id).last();
      const weeks = this.buildWeeks(lastscs.date);
      final.total = 0;
      final.lastTotal = 0;
      final.datasets = [];

      // build data
      for(let w in weeks){
        const result = {};
        result.data = [];
        result.label = weeks[w].label;
        if(weeks[w].thisweek){
          result.backgroundColor = ['#FF3300'];
          result.borderWidth = 8;
        }else{
          result.backgroundColor = ['#AAA']
        }
        const dates = weeks[w].dates;
        for(let d in dates){
          const scs = await this.db.scs.where({'sid': site.id, 'date': dates[d]}).toArray();
          let num = 0;
          const tmpnum = scs.reduce(function(sum, el){return sum + el[val];}, 0);

          // %化
          if(val === 'ctr'){
            num = (tmpnum * 100).toFixed(2);
          }else if(val === 'pos'){
            num = tmpnum.toFixed(2);
          }else{
            num = tmpnum;
          }
          result.data.push(num);
          if(weeks[w].thisweek){
            final.labels.push(this.$dayjs(dates[d]).format('M/D (ddd)'));
            final.total += tmpnum;
          }else{
            final.lastTotal += tmpnum
          }
        }
        final.datasets.push(result);
      }
      if(totalType === 'ave'){
        final.total = final.total / 7;
        final.lastTotal = final.lastTotal / 7;

        if(val === 'ctr'){
          final.total = final.total * 100;
          final.lastTotal = final.lastTotal * 100;
        }
        final.total = final.total.toFixed(2);
        final.lastTotal = final.lastTotal.toFixed(2);
      }
      final.comapre = (((final.total / final.lastTotal) *100) -100 ).toFixed(2);
      return final;
    },
    async getTopUrl(site){
      const result = {'date': '', 'list': []};
      const lastscp = await this.db.scp.where({
        'sid': site.id
      }).last();

      if(lastscp !== undefined){
        result.date = this.$dayjs(lastscp.date).format('YYYY/M/D');

        const query = await this.db.scp.where({
          'sid': site.id,
          'date': lastscp.date
        })
        .reverse()
        .sortBy('clk')
        .then((value) => {
          let cnt = 0;
          const result = [];
          for(let i in value){
            cnt++;
            if(cnt < 31){
              result.push(value[i])
            }else{
              break;
            }
          }
          return result;
        });
        for(let q in query){
          const tmp = query[q]
          tmp.site = site.name;
          result.list.push(tmp)
        }
      }
      return result;
    },
    async getQuerylist(site){
      const result = {'date': '', 'querylist': []};
      const lastscq = await this.db.scq.where({
        'sid': site.id
      }).last();

      if(lastscq !== undefined){
        result.date = this.$dayjs(lastscq.date).format('YYYY/M/D');

        const query = await this.db.scq.where({
          'sid': site.id,
          'date': lastscq.date
        })
        .reverse()
        .sortBy('clk')
        .then((value) => {
          let cnt = 0;
          const result = [];
          for(let i in value){
            cnt++;
            if(cnt < 31){
              result.push(value[i])
            }else{
              break;
            }
          }
          return result;
        });
        for(let q in query){
          const tmp = query[q]
          tmp.site = site.name;
          result.querylist.push(tmp)
        }
      }
      return result;
    }
  }
}
</script>
