<template>
  <div class=" mb-10">
    <h3 class="text-xl leading-none font-bold text-gray-900 mb-2">Top URL</h3>
    <span class="text-base font-normal text-gray-500">{{date}}</span>
  </div>
  <div class="block w-full overflow-x-auto">
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <th class="px-4 bg-gray-50 text-gray-700 align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">Site</th>
          <th class="px-4 bg-gray-50 text-gray-700 align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap">URL</th>
          <th class="px-4 bg-gray-50 text-gray-700 align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap min-w-140-px">CLK</th>
          <th class="px-4 bg-gray-50 text-gray-700 align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap min-w-140-px">IMP</th>
          <th class="px-4 bg-gray-50 text-gray-700 align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap min-w-140-px">CTR</th>
          <th class="px-4 bg-gray-50 text-gray-700 align-middle py-3 text-xs font-semibold text-left uppercase border-l-0 border-r-0 whitespace-nowrap min-w-140-px">Rank</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-100">
        <tr class="text-gray-500" v-for="(data, di) in list" :key="'di-' + di">
          <th class="border-t-0 px-4 align-middle text-sm font-normal whitespace-nowrap p-4 text-left">
            <router-link :to="'/sites/' + data.site + '/'">
              {{data.site}}
            </router-link>
          </th>
          <td class="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">
            <a class="text-blue-500" :href="data.page" target="_blank">
              {{data.page}}
            </a>
          </td>
          <td class="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">{{data.clk}}</td>
          <td class="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">{{data.imp}}</td>
          <td class="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">{{(data.ctr * 100).toFixed(2)}}%</td>
          <td class="border-t-0 px-4 align-middle text-xs font-medium text-gray-900 whitespace-nowrap p-4">{{data.pos.toFixed(1)}}</td>
        </tr>
      </tbody>
    </table>
  </div>

</template>

<script>

export default {
  name: 'TopUrl',
  props: ['list', 'date'],
}
</script>
